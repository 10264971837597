import '../styles/style.css';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faSave, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import {
    userName, passCode, businessEntityKey, apiUrl,
    loadedRoute, routeSelected, displayRoute, loadingActive, modalMessage, modalVisible, modalType,
    searchValue, searchResults //, cancelCodes
} from '../atoms';

export default function Route() {
    // Read only variables
    const user = useRecoilValue(userName);
    const password = useRecoilValue(passCode);
    const businessKey = useRecoilValue(businessEntityKey);
    const url = useRecoilValue(apiUrl);
    //const cCodes = useRecoilValue(cancelCodes);

    // Two way binding
    const [route, setRoute] = useRecoilState(loadedRoute);
    const [selectedRoute, setSelectedRoute] = useRecoilState(routeSelected);
    //const [showCancelOptions, setShowCancelOptions] = useState(false);
    /*const [selectedCancelCode, setSelectedCancelCode] = useState("");
    const [selectedStop, setSelectedStop] = useState("");*/
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [newMobileDeviceNumber, setNewMobileDeviceNumber] = useState("");

    // Sets variable values
    const setShowRoute = useSetRecoilState(displayRoute);
    const setLoading = useSetRecoilState(loadingActive);
    const setModalMessage = useSetRecoilState(modalMessage);
    const setModalVisible = useSetRecoilState(modalVisible);
    const setModalType = useSetRecoilState(modalType);
    const setSearch = useSetRecoilState(searchValue);
    const setRouteResults = useSetRecoilState(searchResults);

    const CloseRouteEditing = () => {
        setShowRoute(false);
        setSelectedRoute(undefined);
        setRoute(undefined);
    }

    const showInfoPopup = () => {
        if (showTooltip) {
            setShowTooltip(false);
        }
        else {
            setShowTooltip(true);
        }
    }

    /*const CloseCancelModal = () => {
        setShowCancelOptions(false);
    }*/

    const CloseSaveModal = () => {
        setShowSaveModal(false);
    }

    const ShowSaveConfirmation = () => {
        setShowSaveModal(true);
    }

    const showPopup = (message, type) => {
        setModalMessage(message);
        setModalType(type);
        setShowSaveModal(false);
        setModalVisible(true);
        setLoading(false); // hide loading animation
    }

    const UpdateNewDeviceID = (event) => {
        setNewMobileDeviceNumber(event.target.value);
    }

    const UpdateMobileDevice = () => {
        if (newMobileDeviceNumber && newMobileDeviceNumber.trim() !== "") {
            setSelectedRoute(prev => {
                return { ...prev, mobileDevicePhoneNumber: newMobileDeviceNumber };
            });
        }
    }

    const UpdateArrival = (updatedStop, event) => {
        setRoute(prev => {
            return prev.map(s => {
                if (updatedStop.stopNum === s.stopNum) {
                    return { ...s, arrival: event.target.value, changed: true };
                }
                return s;
            });
        });

        setSelectedRoute(prev => {
            return { ...prev, changed: true };
        });
    }

    const UpdateDeparture = (updatedStop, event) => {
        setRoute(prev => {
            return prev.map(s => {
                if (updatedStop.stopNum === s.stopNum) {
                    return { ...s, departure: event.target.value, changed: true };
                }
                return s;
            });
        });

        setSelectedRoute(prev => {
            return { ...prev, changed: true };
        });
    }

    /*const UpdateCancel = (updatedStop, event) => {
        if (!event.target.checked) {
            setRoute(prev => {
                return prev.map(s => {
                    if (updatedStop.stopNum === s.stopNum) {
                        return { ...s, cancelled: event.target.checked, changed: false };
                    }
                    return s;
                });
            });
        }
        else {
            setSelectedStop(updatedStop); // Making sure to hold the stop information for updating the cancel code later
            setShowCancelOptions(true);
        }
    }*/

    // This is used to determine if the user is actually wanting to save changes
    const UpdateSave = (updatedStop, event) => {
        setRoute(prev => {
            return prev.map(s => {
                if (updatedStop.stopNum === s.stopNum) {
                    return { ...s, save: event.target.checked };
                }
                return s;
            });
        });
    }

    /*const selectCode = (item, event) => {
        setSelectedCancelCode(item);
    }

    const SaveCancelCode = () => {
        setShowCancelOptions(false);
        setRoute(prev => {
            return prev.map(s => {
                if (selectedStop.stopNum === s.stopNum) {
                    if (!selectedCancelCode) {
                        setSelectedCancelCode(cCodes[0]);
                        return { ...s, cancelled: true, changed: true, cancelreason: cCodes[0].entityKey, cancelreasonValue: cCodes[0].code };
                    }
                    else {
                        return { ...s, cancelled: true, changed: true, cancelreason: selectedCancelCode.entityKey, cancelreasonValue: selectedCancelCode.code };
                    }
                }
                return s;
            });
        });

        setSelectedRoute(prev => {
            return { ...prev, changed: true };
        });
    }*/

    const formatPhone = (phone) => {
        if (phone.length === 10) {
            return phone.slice(0, 3) + "-" + phone.slice(3, 6) + "-" + phone.slice(6);
        }

        return phone;
    }

    const formatDate = (date) => {
        let nDate = new Date(date);
        if (nDate) {
            return " " + nDate.getFullYear() + "-" + ("0" + nDate.getMonth()+1).slice(-2) + "-" + ("0" + nDate.getDate()).slice(-2)
                + " " + ("0" + nDate.getHours()).slice(-2) + ":" + ("0" + nDate.getMinutes()).slice(-2);
        }

        return "";
    }

    const ConvertToGMZ = (oTimestamp) => {
        var newTime = undefined;

        // If empty, return undefined
        if(oTimestamp === null || oTimestamp === undefined) {
            return undefined;
        }
        else { // Convert to local time by creating new object
            newTime = new Date (oTimestamp);
        }
        
        return newTime.getUTCFullYear() + "-" + ("0" + (newTime.getUTCMonth()+1)).slice(-2) + "-" + ("0" + newTime.getUTCDate()).slice(-2)
        + "T" + ("0" + newTime.getUTCHours()).slice(-2) + ":" + ("0" + newTime.getUTCMinutes()).slice(-2);
    }

    const SaveStopChanges = () => {
        setLoading(true); // hide loading animation
        let jsonBody = {};
        let loginBody = {}; // sub section for logging in
        loginBody.username = user;
        loginBody.password = password;
        jsonBody.loginBody = loginBody;

        let jRoute = {}; // sub section for route data
        jRoute.region = selectedRoute.regionIdentity.entityKey;
        jRoute.routeEntityKey = selectedRoute.identity.entityKey;
        jRoute.businessEntityKey = businessKey;
        jRoute.mobileDevice = selectedRoute.mobileDeviceEntityKey.toString();
        jRoute.mobileDevicePhoneOrId = selectedRoute.mobileDevicePhoneNumber ? selectedRoute.mobileDevicePhoneNumber : " ";
        jRoute.dataQuality = 20;
        jRoute.version = selectedRoute.version; // Might need to add one for each stop that is updated   

        let stops = [];
        let stopCount = 0;
        let isChangingSomething = false;

        // Loop through the results to determine which ones need to be saved
        for (var n = 0; n < route.length; n++) {
            if (route[n].changed && route[n].save) { // If changed and checked for saving
                let change = {};
                isChangingSomething = true;

                // Load stops into single array object for simple handling
                if (route[n].cancelled) { 
                    change.actionType = "cancelStop";
                    change.arrivalTimeStamp = new Date(1, 0, 1).toISOString();
                    change.departTimeStamp = new Date(1, 0, 1).toISOString();
                    change.stopCancelReasonCodeKey = route[n].cancelreason; 
                }
                else {
                    change.actionType = "stopTimes";
                    change.arrivalTimeStamp = ConvertToGMZ(route[n].arrival);
                    change.departTimeStamp = ConvertToGMZ(route[n].departure);
                    change.stopCancelReasonCodeKey = 0;
                }

                change.timeStamp = new Date().toISOString();
                change.stopEntityKey = route[n].locentityKey;

                // Add stop to the stops json
                stops[stopCount] = change;
                stopCount++;
            }
        }

        if(!isChangingSomething) {
            showPopup("There isn't anything new to save.", "error");
        }

        // Combine the jsons together
        jsonBody.stops = stops;
        jsonBody.route = jRoute;

        // Commit all stop changes
        
        fetch(url + "SaveAllStopChanges",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonBody),
            }).then((response) => response.json())
            .then((data) => {
                setLoading(false); // hide loading animation

                if (data && data.status === 200) { // Display prompts at end
                    CloseRouteEditing(); // Initiate closing the modal and clearing variable data
                    setSearch(""); // Clear search text since the route is updated and no longer available to work on
                    setRouteResults(undefined); // Clear search results since the route is updated and no longer available to work on
                    showPopup("All changes to the route saved successfully. The route can now be unsuspended by the driver if applicable.", "success");// Show successful message in popup
                }
                else if(data && data.message) {
                    showPopup(data.message, "error");
                }
                else {
                    showPopup("Failed to save new stop information  correctly. Please try re-submitting the changes or reloading the route again.", "error");
                }
            })
            .catch((error) => {
                showPopup(error, "error");
                console.log(error);
            });
    }

    return (
        <div className="modal-block">
            <div className="modal-block-back-tall">
                <div className="modal-page h-float">
                    <div className="modal-header modal-header-small modal-header-regular">
                        <div className="v-float h-float">Route: {selectedRoute.identity.identifier + " - " + selectedRoute.identity.entityKey}</div>
                        <div className="modal-close modal-close-purple"><button onClick={CloseRouteEditing}><FontAwesomeIcon icon={faX} /></button></div>
                    </div>
                    <div className="modal-body">
                        <div className="div-row">
                            <div className="format-center format-bold width-20 v-float"><label htmlFor="routeMobileID ">Associated Mobile Device</label></div>
                            {selectedRoute.mobileDevicePhoneNumber ?
                                <div className="format-left width-30"><input type="text" id="routeMobileID" className="stop-input stop-input-icon" value={"Associated Device (" + formatPhone(selectedRoute.mobileDevicePhoneNumber) + ")"} disabled /></div>
                                :
                                selectedRoute.mobileDevice ?
                                    <div className="format-left width-30"><input type="text" id="routeMobileID" className="stop-input stop-input-icon" disabled value={"Associated Device (" + selectedRoute.mobileDevice + " - " + selectedRoute.mobileDeviceIdentifier + ")"} /></div>
                                    :
                                    <div className="format-left width-30 flex">
                                        <div><input type="text" id="routeMobileID" className="stop-input stop-input-icon" maxLength={"10"} value={newMobileDeviceNumber} onChange={UpdateNewDeviceID} /></div>
                                        <div><button className="search-device-icon" onClick={UpdateMobileDevice}><FontAwesomeIcon icon={faSave} /></button></div>
                                    </div>
                            }
                            <div className='width-50'></div>
                            <div className='format-right width-05'><button className="search-device-icon" onClick={showInfoPopup}><FontAwesomeIcon icon={faCircleInfo} /></button></div>
                            {showTooltip &&
                                <div className="popup">
                                    <div className="popup-block">
                                            <div className='popup-header'>
                                                <div className='width-80 v-float'>
                                                    Information
                                                </div>
                                                <div className='width-05'>
                                                    <button className="search-device-icon" onClick={showInfoPopup}><FontAwesomeIcon icon={faX} /></button>
                                                </div>
                                            </div>
                                            <div className='popup-body'>
                                                <div className='popup-text'>
                                                    Origin and Destination stops are not changeable at this time.
                                                </div>
                                                <div className='popup-text'>
                                                    To save a stop, change either the arrival or departure times for the stop, or click the cancel checkbox.
                                                    Then make sure to check the save box to ensure changes are saved.
                                                </div>
                                                <div className='popup-text'>
                                                    Click the <b>Save Changes</b> button at the bottom to review your changes.
                                                </div>
                                                <div className='popup-text'>
                                                    If something is incorrect, close the route using the <b>x</b> button at the top right of the page. Then open the route
                                                    again to refresh the information.
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="div-row div-row-header">
                            <div className="format-left width-05">#</div>
                            <div className="format-center width-20">Location</div>
                            <div className="format-center width-30">Arrival</div>
                            <div className="format-center width-30">Departure</div>
                            <div className="format-center width-15">Actions</div>
                        </div>
                        {route !== undefined && route.length > 0 ? route.map((item, i) => (
                            <div className="div-row div-row-data">
                                <div className="format-left width-05 v-float"><b>{item.stopNum}</b></div>
                                <div className="format-left width-30">
                                    <div className="flex">
                                        <div className="width-40"><b>Description: </b></div>
                                        <div className="width-60">{item.locdescription.toUpperCase()}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="width-40"><b>Identifier: </b></div>
                                        <div className="width-60">{item.locidentifier.toUpperCase()}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="width-40"><b>Entity Key: </b></div>
                                        <div className="width-60">{item.locentityKey}</div>
                                    </div>
                                    {item.stopType === "service" &&
                                        <>
                                            <div className="flex">
                                                <div className="width-40"><b>Address: </b></div>
                                                <div className="width-60">{item.locaddress.toUpperCase()}</div>
                                            </div>
                                            <div className="flex">
                                                <div className="width-40"><b>City: </b></div>
                                                <div className="width-60">{item.loccity.toUpperCase()}</div>
                                            </div>
                                            <div className="flex">
                                                <div className="width-40"><b>State/Province: </b></div>
                                                <div className="width-60">{item.locstate.toUpperCase()}</div>
                                            </div>
                                            <div className="flex">
                                                <div className="width-40"><b>Postal Code: </b></div>
                                                <div className="width-60">{item.loczipcode}</div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="format-center width-25 v-float">
                                    {/* Arrival times. If origin/destination or service stop that is cancelled, locked */}
                                    {item.stopType === "origin" || item.stopType === "destination" || (item.stopType === "service" && item.cancelled) ?
                                        <input type="datetime-local" className="stop-input stop-input-input" value={item.arrival} disabled />
                                        :
                                        <input type="datetime-local" className="stop-input stop-input-input" value={item.arrival} onInput={(event) => UpdateArrival(item, event)} />
                                    }
                                </div>
                                <div className="format-center width-25 v-float">
                                    {/* Departure times. If origin/destination or service stop that is cancelled, locked */}
                                    {item.stopType === "origin" || item.stopType === "destination" || (item.stopType === "service" && item.cancelled) ?
                                        <input type="datetime-local" className="stop-input stop-input-input" value={item.departure} disabled />
                                        :
                                        <input type="datetime-local" className="stop-input stop-input-input" value={item.departure} onInput={(event) => UpdateDeparture(item, event)} />
                                    }
                                </div>
                                <div className="format-center width-15 v-float">
                                    {(item.stopType === "service" && item.locked) ?
                                        <>
                                            <div className="stop-actions">
                                                <div className="stop-action">
                                                    <div><input type="checkbox" className="stop-action-input" checked disabled title='Stop was cancelled previously' /></div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        (item.stopType === "service" && !item.cancelled) ?
                                            <div className="stop-actions">
                                                <div className="stop-action">{/*onClick={(event) => UpdateCancel(item, event)} move to input on line below when ready */}
                                                    <div><input type="checkbox" id={"cancel" + item.stopNum} className="stop-action-input" disabled title='Checking this will cancel the stop regardless of arrival/departure time values.' /></div>
                                                    <div className="stop-action-label-small"><label htmlFor={"cancel" + item.stopNum}>Cancel</label></div>
                                                </div>
                                                <div className="stop-action">
                                                    <div><input type="checkbox" id={"update" + item.stopNum} className="stop-action-input" onClick={(event) => UpdateSave(item, event)} title='Check this box to save changes for the row including cancel or arrival/departure times.' /></div>
                                                    <div className="stop-action-label-small"><label htmlFor={"update" + item.stopNum}>Save</label></div>
                                                </div>
                                            </div>
                                            :
                                            (item.stopType === "service" && item.cancelled) ?
                                                <div className="stop-actions">
                                                    <div className="stop-action">{/*onClick={(event) => UpdateCancel(item, event)} move to input on line below when ready */}
                                                        <div><input type="checkbox" id={"cancel" + item.stopNum} className="stop-action-input" checked  title='Checking this will cancel the stop regardless of arrival/departure time values.' /></div>
                                                        <div className="stop-action-label-small"><label htmlFor={"cancel" + item.stopNum}>Cancel - {item.cancelreasonValue}</label></div>
                                                    </div>
                                                    <div className="stop-action">
                                                        <div><input type="checkbox" id={"update" + item.stopNum} className="stop-action-input" onClick={(event) => UpdateSave(item, event)} title='Check this box to save changes for the row including cancel or arrival/departure times.' /></div>
                                                        <div className="stop-action-label-small"><label htmlFor={"update" + item.stopNum}>Save</label></div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                    }
                                </div>
                            </div>
                        ))
                            :
                            <div className="div-row">No stops were found for this route. If this is a mistake, please close and reopen the route again.</div>
                        }
                        {route !== undefined && route.length > 0 &&
                            <div className="div-row">
                                <div className="modal-row-button width-60 h-float">
                                    <button onClick={ShowSaveConfirmation}>Save Changes</button>
                                </div>
                            </div>
                        }
                        {/* Removed currently until feature is ready.
                        showCancelOptions &&
                            <div className="modal-block">
                                <div className="modal-block-back-short">
                                        <div className="modal-header modal-header-large modal-header-regular">
                                            <div className="v-float h-float">Cancel Code Option</div>
                                            <div className="modal-close modal-close-purple width-12"><button onClick={CloseCancelModal}><FontAwesomeIcon icon={faX} /></button></div>
                                        </div>
                                        <div className="modal-body">
                                            <div className="modal-row">
                                                {cCodes !== undefined && cCodes.length > 0 ?
                                                    <div className='div-row' >
                                                        <div className='format-center format-bold format-med width-40 v-float' >Reasons </div>
                                                        <div className='format-center width-60' >
                                                            <select className='stop-input'>
                                                                {cCodes.map((item, i) => (
                                                                    <option value={item.entityKey} onClick={(event) => selectCode(item, event)}>{item.code}</option>
                                                                ))
                                                                }
                                                            </select></div>
                                                    </div >
                                                    :
                                                    <div className="div-row">The cancel codes didn't load correctly. Please reopen this route and try again.</div>
                                                }
                                            </div>
                                            <div className="modal-row">
                                                <div className="modal-row-button modal-row-button-error width-100">
                                                    <button onClick={SaveCancelCode}>Select</button>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>*/
                        }
                        {showSaveModal &&
                            <div className="modal-block">
                                <div className="modal-block-back-short">
                                        <div className="modal-header modal-header-large modal-header-regular">
                                            <div className="v-float h-float">Save Route Changes</div>
                                            <div className="modal-close modal-close-purple width-12"><button onClick={CloseSaveModal}><FontAwesomeIcon icon={faX} /></button></div>
                                        </div>
                                        <div className="modal-body">
                                            {selectedRoute.changed ?
                                                <>
                                                    {newMobileDeviceNumber &&
                                                        <div className="modal-row">
                                                            <div className="div-row">Mobile number {formatPhone(newMobileDeviceNumber)} is being assigned to this route.</div>
                                                        </div>
                                                    }
                                                    {
                                                        route.map((item, i) => (
                                                            item.changed && item.save &&
                                                            <div className="modal-row modal-row-border">
                                                                <div className="modal-row-header v-float">Stop {item.stopNum}:</div>
                                                                {item.cancelreasonValue ?
                                                                    <div className='modal-row-data'>
                                                                        <div className="width-50 format-left">Cancelling for reason:</div>
                                                                        <div className="width-50 format-center">{item.cancelreasonValue}</div>
                                                                    </div>
                                                                    :
                                                                    <div className='width-100'>
                                                                        <div className='modal-row-data'>
                                                                            <div className="width-50 format-left">Arrival date/time:</div>
                                                                            <div className="width-50 format-center">{formatDate(item.arrival)}</div>
                                                                        </div>
                                                                        {item.departure &&
                                                                            <div className='modal-row-data'>
                                                                                <div className="width-50 format-left">Departure date/time:</div>
                                                                                <div className="width-50 format-center">{formatDate(item.departure)}</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                    <div className="modal-row">
                                                        <div className="modal-row-button modal-row-button-error width-100">
                                                            <button onClick={SaveStopChanges}>Save</button>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className="modal-row">
                                                    <div className="div-row">No changes were made to the form. Please make changes to the route before trying to save.</div>
                                                </div>

                                            }
                                        </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}
